.single-chart {
  width: 100%;
  justify-content: space-around;
  margin: 0 auto;
}

@media screen and (max-height: 560px) {
  .single-chart {
    width: 75%;
  }
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 95%;
  /*max-height: 250px;*/
  max-height: calc(100vh - 46.5rem);
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  /*animation: progress 1s ease-out forwards;*/
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.timing .circle-bg {
  stroke: rgba(144, 164, 174, 0.3);
  /*stroke: #eee;*/
}

.circular-chart.timing .circle {
  stroke: #90a4ae;
}

.circular-chart.shift .circle-bg {
  stroke: rgba(41, 121, 255, 0.3);
}

.circular-chart.shift .circle {
  stroke: #2979ff;
}

.circular-chart.batch .circle-bg {
  stroke: rgba(0, 200, 83, 0.3);
}

.circular-chart.batch .circle {
  stroke: #00c853;
}

.circle-title span,
.circle-value span {
  display: inline-block;
}

.circle-title,
.circle-value,
.circle-subtitle,
.circle-subvalue {
  text-anchor: middle;
}

.circle-title {
  font-size: 0.35rem;
  fill: rgb(120, 144, 156);
}

.circle-value {
  font-size: 0.425rem;
  fill: #263238;
  font-weight: 500;
}

.circle-subtitle {
  font-size: 0.25rem;
  fill: #78909c;
}

.circle-subvalue {
  font-size: 0.28rem;
  fill: #263238;
}
