html {
    overflow-y: unset;
    font-size: 62.5%;
}

@media screen and (max-width: 980px) {
    html {
        font-size: 50% !important;
    }
}

@media screen and (max-height: 640px) {
    html {
        font-size: 45% !important;
    }

    .circle-title {
        font-size: 0.65rem;
    }

    .circle-value {
        font-size: 0.55rem;
    }

    .circle-subtitle {
        font-size: 0.35rem;
    }

    .circle-subvalue {
        font-size: 0.4rem;
    }

    .button.is-large {
        height: 7.5rem !important;
    }
}

body {
    font-family: LLCircularWeb, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 1.5rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.keyboard-container {
    width: calc(100vw - 2rem);
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.keyboard-button {
    font-family: LLCircularWeb, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 2.4rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.section {
    background-color: transparent;
}

.is-fullheight {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /*justify-content: space-between;*/
    justify-content: flex-start;
    min-height: calc(100vh - 4rem);
    border-radius: 6px;
    background-color: white;
}

.has-text-centered figure {
    margin: 0 auto;
}

.has-text-left figure {
    margin: initial;
}

/** Prevent modal error **/
.is-fullheight .button,
.is-fullheight .notification {
    position: initial;
}

.modal-content {
    max-width: calc(100vw - 10rem);
    max-height: calc(100vh - 10rem);
}

.notification {
    position: relative !important;
    background-color: #cfd8dc;
    min-height: 7.2rem;
    border-radius: 0.6rem;
    padding: 2.5rem 2.5rem 1.25rem 1.5rem;
}

textarea,
input {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    color: #455a64;
    width: 100%;
    outline: none;
    background-color: #cfd8dc;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    position: relative !important;
    min-height: 7.2rem;
}

@keyframes background-pulsate-error {
    0% {
        background-color: rgba(207, 216, 220, 1);
    }
    50% {
        background-color: rgba(245, 0, 87, 0.2);
    }
    100% {
        background-color: rgba(207, 216, 220, 1);
    }
}

.validation-error {
    animation: background-pulsate-error 0.4s;
}

.image.is-48x48 {
    height: 4.8rem;
    width: 4.8rem;
}

.title.is-1,
.subtitle.is-1 {
    font-size: 5.6rem;
}

.title.is-2,
.subtitle.is-2 {
    font-size: 4rem;
}

.title.is-3,
.subtitle.is-3 {
    font-size: 3.2rem;
}

.title.is-4,
.subtitle.is-4 {
    font-size: 2.4rem;
}

.title.is-5,
.subtitle.is-5 {
    font-size: 2rem;
}

.title.is-6,
.subtitle.is-6 {
    font-size: 1.6rem;
}

/*.title:not(.is-spaced) + .subtitle {*/
/*margin-top:-2.5rem !important;*/
/*}*/

.img-rounded,
.img-rounded > div,
.img-rounded > img {
    border-radius: 0.8rem !important;
}

a,
a:hover,
a:active,
a:focus,
a:visited {
    color: #263238;
}

.swal2-popup {
    font-size: 1.6rem !important;
    font-family: LLCircularWeb, 'Helvetica Neue', Helvetica, sans-serif !important;
    width: 30vw;
}

.doc-image p {
    font-size: 1.8rem;
}

.doc-image img {
    width: 11.2rem;
    height: 7.2rem;
    object-fit: contain;
}

/*.hero-head {*/
/*padding: 2rem 3rem;*/
/*}*/
/*.hero-head.has-bottom-border {*/
/*border-bottom: 0.2rem solid #cfd8dc;*/
/*}*/
/*.hero-body {*/
/*padding: 1rem 3rem;*/
/*!*align-items: flex-start !important;*!*/
/*align-items: stretch !important;*/

/*max-height: calc(100vh - 26rem);*/
/*overflow: hidden;*/
/*margin: 1rem;*/
/*}*/
/*.hero-foot {*/
/*padding: 0 3rem 3rem;*/
/*min-height: 12rem;*/
/*}*/

.bom-item:last-child .separator {
    display: none;
}

.table td {
    border: 0 !important;
}

.table tr:hover {
    background-color: transparent !important;
}

.image img.is-rounded {
    border-radius: 6%;
}

.image.is-256x256 {
    height: 256px;
    width: 256px;
}

.swal2-radio label {
    text-align: center;
    font-size: 2.8rem !important;
}

/*@keyframes background {*/
/*  0%   {background: rgba(255,255,255,.6);}*/
/*  50%  {color: red;}*/
/*  100%   {background: rgba(255,255,255,.6);}*/
/*}*/

/*.light-bulb-container {*/
/*  animation-name: mymove;*/
/*  animation: mymove 5s 1;*/
/*}*/
/*@keyframes mymove {*/
/*  0%   {top: 0px;}*/
/*  25%  {top: 200px;}*/
/*  50%  {top: 100px;}*/
/*  75%  {top: 200px;}*/
/*  100% {top: 0px;}*/
/*}*/

a.disabled {
    pointer-events: none;
}